// HEADER

header {
  @include media-breakpoint-up(xs) {
    background-color: #142046;
  }
  @include media-breakpoint-up(xl) {
    background: $background-gradient;
  }
  

  .container {
    position: relative;
    justify-content: flex-end;

    //logo
    #logo {
      display: inline-block;
      position: absolute;
      z-index: $zindex-dropdown;
      top: rem-calc(55);
      left: rem-calc(25);
      @include media-breakpoint-up(lg) {
        top: rem-calc(37);
        left: $grid-gutter-width/2;
      }
      img {
        width: 159px;
        height: 62px;
        @include media-breakpoint-up(md) {
          width: 195px;
          height: 76px;
        }
      }
    }

    //Utility Menu
    #utility {
        @include make-row();
        &> div {
          width: 100%;
          @include media-breakpoint-up(lg) {
            @include make-col-ready();
            @include make-col(12);
            width: auto;
          }
          ul {
            width: 100%;
            @include media-breakpoint-up(lg) {
              justify-content: flex-end;
              width: auto;
            }
            a {
              color: $white;
              @media (max-width: 374.98px) {
                @include fontSize(12px);
              }
              @include fontSize(14px);

              text-align: center;
              line-height: 1.3;
              padding: rem-calc(6) rem-calc(7);
              @include media-breakpoint-up(lg) {
                border-radius: 0 0 $border-radius $border-radius;
                width: rem-calc(110);
              }
              &:hover {
                opacity: .85;
              }
            }

            li {
              &:first-child {
                width: 55%;
                @include media-breakpoint-up(md) {
                  width: 50%;
                }
                @include media-breakpoint-up(lg) {
                  width: auto;
                }
                border-right: rem-calc(2) solid transparent;
                a {
                  background: $professional-color;
                }
              }

              &:last-child {
                width: 45%;
                @include media-breakpoint-up(md) {
                  width: 50%;
                }
                @include media-breakpoint-up(lg) {
                  width: auto;
                }
                border-left: rem-calc(2) solid transparent;
                a {
                  background: $patient-color;
                }
              }
            }
          }
      }
    }

    //primary Menu
    #primary-navigation {
      @include make-row();
      &> nav {
        @include make-col-ready();
        @include make-col(12);

        ul {
          @include media-breakpoint-down(md) {
            display: none;
            background: $white;
            position: absolute;
            width: rem-calc(768);
            left: rem-calc(-25);
            top: rem-calc(130);
            z-index: $zindex-dropdown;
            padding-left: rem-calc(35);
            padding-right: rem-calc(35);
            box-shadow: 0 5px 7px 1px rgba(0,0,0,.6);
          }
          @include media-breakpoint-down(sm) {
            top: rem-calc(113);
            left: 0;
            width: 100%;
            padding-left: rem-calc(25);
            padding-right: rem-calc(25);
          }
          justify-content: flex-end;
          margin-right: -$grid-gutter-width/2;
          @include media-breakpoint-up(lg) {
            margin-top: rem-calc(20);
          }

          li {
            text-align: center;
            @include media-breakpoint-down(md) {
              &:not(:last-child) {
                border-bottom: $professional-color rem-calc(1) solid;
              }
            }

            a {
              @include media-breakpoint-down(md) {
                padding-top: rem-calc(15);
                padding-bottom: rem-calc(15);
              }
              color: $body-color;
              @include fontSize(24px);
              text-transform: uppercase;
              @include media-breakpoint-up(lg) {
                color: $white;
                text-shadow: 0 3px 5px #000000;
                @include fontSize(15px);
              }

              &:hover {
                opacity: .85;
              }
            }

            &.active {
              a {
                color: $success;
                font-weight: $font-weight-medium;
              }
            }
          }
        }

        //Show Menu
        .menu-toggle.up {
          @include media-breakpoint-down(md) {
            &+ ul {
              display: block;
            }
          }
        }
      }
    }

  }
}

//GRADIENT BASE
.front {
  header {
    .base-gradient {
      background: $primary-gradient;
      height: rem-calc(23);
    }
  }
}

.not-front {
  header {
    .container {
      height: 100%;
      &> div {
        height: 100%;
        h1 {
          color: $white;
          letter-spacing: rem-calc(3);
          text-transform: uppercase;
          margin-top: rem-calc(55);
          @include media-breakpoint-up(md) {
            margin-top: rem-calc(35);
          }
          margin-bottom: 0;
          text-align: center;
          text-shadow: 0 3px 5px #000000;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%);
          width: 100%;
          //@include fontSize(46px);
        }
      }
    }
  }

  .base-gradient {
    background: $primary-gradient;
    height: rem-calc(8);
  }

  //h1 doulble lined
  &#patients {
    h1 {
      @include media-breakpoint-down(sm) {
        margin-top: rem-calc(25);
      }
    }
  }
  &#how-to-purchase {
    h1 {
      @media (max-width: 374.98px) {
        margin-top: rem-calc(25);
      }
    }
  }
}
