// NAV ICON

.menu-toggle {
  z-index: $zindex-sticky;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  background: transparent;
  border: 0;
  width: 50px;
  height: 27px;
  position: absolute;
  top: rem-calc(45);
  right: rem-calc(20);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: $white;
    border-radius: rem-calc(3);
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 12px;
    }
    &:nth-child(3) {
      top: 24px;
    }
  }
  &.up {
    span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        top: 11px;
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        top: 11px;
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
