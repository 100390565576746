// PREFACE

#preface {

  .container {
    &> div {
      @include make-row();
      margin-top: rem-calc(48);
      margin-bottom: rem-calc(48);

      //BOTTLE
      #bottle {
        display: none;
        @include make-col-ready();
        @include make-col(3);
        @include media-breakpoint-up(lg) {
          display: block;
        }

        img {
          // max-width: 100%;
          @include media-breakpoint-down(lg) {
            margin-top: 30px;
          }
        }
      }

      //PREFACE ONE
      #preface-one {
        @include make-col-ready();
        @include make-col(12)
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }
      }

      //PREFACE TWO
      #preface-two {
        margin-top: rem-calc(68);
        @include make-col-ready();
        @include make-col(12)
        @include media-breakpoint-up(md) {
          margin-top: 0;
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(4);
          @include make-col-offset(1);
        }
        @include media-breakpoint-up(xl) {
          padding-right: rem-calc(65);
        }
      }
    }

    .preface-column {
      text-align: center;
      @include media-breakpoint-up(sm) {
        text-align: left;
      }
      img {
        margin-bottom: $paragraph-margin-bottom;
      }

      h3 {
        text-align: left;
        margin-bottom: $headings-margin-bottom;
        @include fontSize(24px);
        color: $primary;
        @include media-breakpoint-up(lg) {
          white-space: nowrap;
        }
      }

      p {
        text-align: left;
        margin-bottom: rem-calc(32);
      }

    }

  }

}
