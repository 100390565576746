// IE10 Warning and browser upgrade
.ie10 {
  display: none;
}

html[data-useragent*='MSIE 10.0'] .ie10 { display: block; }

.browserupgrade {
  padding: 25px;
  margin-bottom: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
}
