// patented-polymer-blend

#patented-polymer-blend {
  align-items: center;
  @include make-row();
  //justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  @include media-breakpoint-up(md) {
    margin-top: rem-calc(45);
  }
  margin-bottom: rem-calc(65);
  &> div {
    article:not(:last-child) {
      margin-bottom: rem-calc(25);
    }
    //@include make-col-ready();
    &:first-child {
      margin-bottom: rem-calc(30);
      @include media-breakpoint-up(md) {
          margin-bottom: 0;
          @include make-col(6);
          padding-right: rem-calc(65);
          //@include make-col-offset(1);
      }
    }

    &:nth-child(2) {
      @include media-breakpoint-up(md) {
          @include make-col(6);
          //padding-left: rem-calc(35);
          //@include make-col-offset(1);
      }
    }

    &:last-child {
      p {
        margin-bottom: 0;
      }
      @include media-breakpoint-up(md) {
          //@include make-col(8);
          //@include make-col-offset(2)
          //text-align: center;
          //margin-right: auto;
          //margin-left: auto;
      }
    }
  }

  h3 {
    @include fontSize(14px);
    color: $primary;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    margin-bottom: rem-calc(2);
  }
}
