// FONTELLO

@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?27380355');
  src: url('../font/fontello.eot?27380355#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?27380355') format('woff2'),
       url('../font/fontello.woff?27380355') format('woff'),
       url('../font/fontello.ttf?27380355') format('truetype'),
       url('../font/fontello.svg?27380355#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?85325049#fontello') format('svg');
  }
}
*/

 .demo-icon {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-display: auto;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  // opacity: .8;

  // For safety - reset parent styles, that can break glyph codes
  font-variant: normal;
  text-transform: none;

  // fix buttons height, for twitter bootstrap
  line-height: 1em;

  // Animation center compensation - margins should be symmetric
  // remove if not needed
  margin-left: .2em;

  // You can be more comfortable with increased icons size
  // font-size: 120%;

  // Font smoothing. That was taken from TWBS
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Uncomment for 3D effect
  // text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

.icon-right-arrow-icon:before { content: '\e800'; } /* '' */
.icon-downloadiconwhite:before { content: '\e801'; } /* '' */

.demo-icon.icon-right-arrow-icon {
  margin-right: 0;
}
