//Billboard Image

//Front Page
.front {
  .billboard-image {
    @include background-2x( '../img/front-billboard', 'jpg', 576px, 661px, center -13px, no-repeat );
    height: 43rem;
    @include media-breakpoint-up(sm) {
      @include background-2x( '../img/front-billboard-sm', 'jpg', 768px, 793px, center top, no-repeat );
      height: rem-calc(793);
    }
    @include media-breakpoint-up(md) {
      @include background-2x( '../img/front-billboard-md', 'jpg', 992px, 1024px, center top, no-repeat );
      height: rem-calc(1000);
    }
    @include media-breakpoint-up(lg) {
      @include background-2x( '../img/front-billboard-lg', 'jpg', 1199px, 498px, center top, no-repeat );
      height: rem-calc(498);
    }
    @include media-breakpoint-up(xl) {
      @include background-2x( '../img/front-billboard-xl', 'jpg', 1900px, 599px, center top, no-repeat );
      height: rem-calc(599);
    }
  }

  #intro {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: rem-calc(288);
    top: rem-calc(290);
    @include media-breakpoint-up(sm) {
      top: rem-calc(385);
      width: rem-calc(385);
    }
    @include media-breakpoint-up(md) {
      top: rem-calc(510);
    }
    @include media-breakpoint-up(lg) {
      width: rem-calc(360);
      left: rem-calc(575);
      top: rem-calc(125);
      transform: none;
      text-align: left;
    }
    @include media-breakpoint-up(xl) {
      left: rem-calc(680);
      top: rem-calc(190);
    }
    h1 {
      text-transform: lowercase;
      color: $success;
      @include fontSize(82px);
      line-height: .9;
      margin-left: rem-calc(-5);
      text-align: left;
      span {
        text-transform: uppercase;
        color: $white;
        @include fontSize(38px);
        display: block;
        font-weight: $font-weight-light;
        letter-spacing: rem-calc(7.5);
        margin-left: rem-calc(5);
        white-space: nowrap;
      }
      @include media-breakpoint-up(sm) {
        margin-left: rem-calc(35);
      }
      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
    }

    h2 {
      color: $success;
      @include fontSize(22px);
      font-weight: $font-weight-light;
      margin-top: rem-calc(14);
      margin-bottom: $headings-margin-bottom;
      letter-spacing: rem-calc(1);
      line-height: 1.3;
      text-align: left;
      @include media-breakpoint-up(sm) {
        width: rem-calc(300);
        margin-left: rem-calc(35);
      }

      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
    }

    p {
      color: $white;
      text-align: left;
      @include media-breakpoint-up(sm) {
        margin-bottom: rem-calc(40);
        margin-left: rem-calc(35);
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(19);
        margin-left: 0;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: rem-calc(40);
        margin-left: 0;
      }
    }
  }
}

//Sub Page
.not-front {
  .billboard-image {
    @include background-2x( '../img/sub-billboard', 'jpg', 576px, 245px, top center, no-repeat );
    height: rem-calc(245);
    @include media-breakpoint-up(sm) {
      @include background-2x( '../img/sub-billboard-sm', 'jpg', 768px, 276px, top center, no-repeat );
      height: rem-calc(276);
    }
    @include media-breakpoint-up(md) {
      @include background-2x( '../img/sub-billboard-md', 'jpg', 992px, 356px, top center, no-repeat );
      height: rem-calc(356);
    }
    @include media-breakpoint-up(lg) {
      @include background-2x( '../img/sub-billboard-lg', 'jpg', 1199px, 270px, top center, no-repeat );
      height: rem-calc(270);
    }
    @include media-breakpoint-up(xl) {
      @include background-2x( '../img/sub-billboard-xl', 'jpg', 1900px, 327px, top center, no-repeat );
      height: rem-calc(327);
    }
  }
}
