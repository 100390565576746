// Callout

#pager {
  align-items: center;
  @include make-row();
  margin-right: auto;
  margin-left: auto;
  margin-bottom: rem-calc(75);
  @include media-breakpoint-up(md) {
    margin-bottom: rem-calc(85);
  }
  &> div {
    text-align: center;
    @include make-col(12);
    //@include make-col-ready();
    @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
    }

    p {
      &:first-child {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

    a {
      display: inline-block;
      width: rem-calc(44);
      height: rem-calc(44);
      border-radius: 100%;
      i {
        margin-left: rem-calc(-2.7);
      }
    }

  }
}
