// Callout

.cta {
  align-items: center;
  @include make-row();
  margin-right: auto;
  margin-left: auto;
  margin-bottom: rem-calc(75);
  @include media-breakpoint-up(md) {
    margin-bottom: rem-calc(85);
  }
  &> div {
    text-align: center;
    @include make-col(12);
    //@include make-col-ready();
    @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
    }

    .h3 {
      margin-bottom: rem-calc(45);
      &+ p {
        margin-top: rem-calc(-40);
        margin-bottom: rem-calc(45);
      }
    }
  }

  &.centered {

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: -20px;
      margin-bottom: 30px;

    }
  }
}
