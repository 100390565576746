// Global

html {
	overflow-x: hidden;
}

body {
	margin-left: auto;
	margin-right: auto;
	overflow-x: hidden!important;
	-ms-overflow-style-x: hidden!important;
}

.nowrap {
	white-space: nowrap;
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

ul.bullets {
	list-style: none;
	padding-left: rem-calc(15);
	li {
		margin-bottom: $headings-margin-bottom;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			content: "•";
			color: $success;
  		display: inline-block;
			padding-right: rem-calc(5);
  		margin-left: rem-calc(-14);
		}
	}
	&.no-space {
		margin-top: rem-calc(-10)
	}
}

#brochure {
	@include media-breakpoint-down(sm) {
		display: none;
	}

	span {
		display: block;
		margin-bottom: 10px;
	}

  	position: relative;
	  
	figcaption {
		@include fontSize(14px);
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		line-height: 1.1;
		color: $primary;
		background: $gray-400;
		
		
		// z-index: -1;
		
		border-radius: $border-radius;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding: rem-calc(15) rem-calc(15) rem-calc(15) rem-calc(15);

		@include media-breakpoint-up(xl) {
			position: absolute;
			top: rem-calc(45);
			left: rem-calc(208);
		}

		@include media-breakpoint-down(xl) {
			margin-top: 10px;
			text-align: center;

			// img {
			// 	display: block;;
			// 	margin: 0 auto;
			// }
		}
	}

	@media (max-width: 1199.98px) {
		img {
			display: block;
			margin: 0 auto;
		}
	}

	a {
		font-size: 12px;
		display: block;
		white-space: nowrap;

		.demo-icon {
			line-height: 1.35em;
			z-index: 2;
		}
	}
}

main {
	a {
		font-weight: $font-weight-bold;
	}
}
