// Callout

.callout {
  align-items: center;
  @include make-row();
  margin-right: auto;
  margin-left: auto;
  margin-bottom: rem-calc(75);
  @include media-breakpoint-up(md) {
    margin-bottom: rem-calc(85);
  }
  &> div {
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);
    border-top: rem-calc(1) $success solid;
    border-bottom: rem-calc(1) $success solid;
    @include make-col(12);
    //@include make-col-ready();
    @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
    }
    .media-body {
      padding-right: rem-calc(35);
      p {
        &:first-child {
          margin-bottom: 0;
          color: $primary;
          font-weight: $font-weight-bold;
          &+ p {
            margin-top: rem-calc(5);
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
