// FOOTER

footer {
  background: $gray-400;
  padding-top: rem-calc(18);
  padding-bottom: rem-calc(18);
  .container {
    &> div {
      @include make-row();
      //@include make-col-ready();
      align-items: center;
      .eyevance-logo {
        margin-bottom: rem-calc(18);
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(lg) {
          @include make-col(4, $columns: 20);
          margin-bottom: 0;
        }
        @include media-breakpoint-up(xl) {
          @include make-col(2);
        }
      }

      .copyright {
        @include make-col-ready();
        @include fontSize(11px);
        @include make-col(12);
        @include media-breakpoint-up(lg) {
          @include make-col(9, $columns: 20);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(5);
        }
      }

      .menu {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(lg) {
          @include make-col(7, $columns: 20);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(5);
        }
        ul {
          margin-left: rem-calc(-8);
          @include media-breakpoint-up(lg) {
            float: right;
            margin-left: auto;
          }
          li {
            @include fontSize(12px);
            &:not(:first-child)::before {
              content: " | ";
              margin-right: rem-calc(-2);
           }
            .nav-link {
              display: inline-block;
              padding-left: rem-calc(8);
              padding-right: rem-calc(8);
              color: $primary;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

    }
  }
}
