// Modal

.modal:before {
  content: "";
  display: none;
  background: rgba($black, .4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-modal-backdrop;
}
.opened:before {
  display: block;
}
.opened .modal-dialog {
  top: 50%;
  @include media-breakpoint-up(sm) {
    top: 45%;
  }
  transform: translate(-50%, -50%);
}
.modal-dialog {
  box-align: center;
  border: solid $success rem-calc(4);
  //border-bottom: solid $red rem-calc(6);
  padding: rem-calc(30) rem-calc(20);
  @include media-breakpoint-up(md) {
    padding: rem-calc(60) rem-calc(80) rem-calc(40) rem-calc(80);
  }
  position: fixed;
  top: -20%;
  left: 50%;
  background: $white;
  text-align:center;
  z-index: $zindex-modal;
  width: 90%;
  @include media-breakpoint-up(sm) {
    width: 65%;
  }
  @include media-breakpoint-up(lg) {
    width: rem-calc(780);
  }
  transform: translate(-50%, -500%);
  transition: transform 1s ease-out;
  animation: fadein 1s;

  .btn.btn-success {
    margin-top: rem-calc(20);
  }

  .modal-close {
    cursor: pointer;
    margin-top: rem-calc(30);
    display: inline-block;
    text-transform: uppercase;
    @include fontSize(16px);
  }

}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
