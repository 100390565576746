// multidose-bottle

#multidose-bottle {
  .container {
    &> div.content {
      @include make-row();
      margin-right: auto;
      margin-left: auto;
      margin-bottom: rem-calc(45);
      //@include make-col-ready();
      &> div {
        &:first-child {
          margin-right: auto;
          margin-left: auto;
          margin-bottom: rem-calc(15);
          @include media-breakpoint-up(md) {
              margin-bottom: 0;
              @include make-col(3);
              padding-right: rem-calc(15);
              //@include make-col-offset(1);
          }
          @include media-breakpoint-up(xl) {
            padding-left: rem-calc(30);
          }
        }

        &:last-child {
          @include media-breakpoint-up(md) {
              @include make-col(8);
              //padding-left: rem-calc(35);
              //@include make-col-offset(1);
          }
        }
      }
    }
  }



}
