// Flow Chart

#about-dry-eye {

  #flow-chart {
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      margin-right: auto!important;
      margin-left: auto!important;
    }
    @include make-row();
    margin-top: rem-calc(42);
    @include media-breakpoint-up(lg) {
      margin-top: rem-calc(68);
    }
    margin-bottom: rem-calc(60);
    @include media-breakpoint-up(md) {
      margin-bottom: rem-calc(85);
    }
    &> div {
      position: relative;
      text-align: center;
      margin-bottom: rem-calc(15);
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(xl) {
        @include make-col(11);
        @include make-col-offset(1);
      }

      &:first-child {
        color: $primary;
        font-weight: $font-weight-bold;
        picture {
          img {
            @include media-breakpoint-up(lg) {
              max-width: 100%;
            }
          }
          &~ div {
            text-align: center;
            width: rem-calc(280);
            position: absolute;
            left: 50%;
            margin-left: rem-calc(-150);
            @include media-breakpoint-up(lg) {
              margin-left: 0;
            }
            z-index: 1;
              &:nth-child(2) {
                top: 60px;
                @include media-breakpoint-up(lg) {
                  top: rem-calc(60);
                  left: rem-calc(2);
                }
                @include media-breakpoint-up(xl) {
                  left: rem-calc(25);
                }
              }
              &:nth-child(3) {
                top: rem-calc(395);
                @include media-breakpoint-up(lg) {
                  top: rem-calc(100);
                  left: rem-calc(305);
                }
                @include media-breakpoint-up(xl) {
                  left: rem-calc(350);
                }
              }
              &:nth-child(4) {
                top: rem-calc(685);
                @include media-breakpoint-up(lg) {
                  top: rem-calc(100);
                  left: rem-calc(613);
                }
                @include media-breakpoint-up(xl) {
                  left: rem-calc(675);
                }
              }

            }
          }
        }

        &:last-child {
          text-align: left;
          max-width: rem-calc(380);
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0;
          @include media-breakpoint-up(xl) {
            padding-left: rem-calc(90);
            max-width: rem-calc(430);
          }
          .h2 {
            text-align: center;
            margin-bottom: $headings-margin-bottom + .5;
          }
          ul {
            margin-bottom: 0;
          }
        }
      }

    }

}
