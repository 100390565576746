// BUTTONS

.btn {
  &.btn-success {
    color: $white;
    text-transform: uppercase;
    letter-spacing: rem-calc(1);
    &::before {
      content: '';
      display: block;
      height: 0;
      width: 0;
      margin-top: calc((1.8 - #{$line-height-base}) * 0.5em);
    }
    i.icon-right-arrow-icon {
      position: relative;
      &:before {
        position: absolute;
        top: rem-calc(-15.3);
        left: rem-calc(6);
      }
    }
    i.icon-downloadiconwhite {
      position: relative;
      font-size: 130%;
      &:before {
        position: absolute;
        top: rem-calc(-18);
        left: rem-calc(3);
      }
    }
  }
}
