// Downloads

#resources {

  #downloads {
    @include make-row();
    margin-top: rem-calc(16);
    @include media-breakpoint-up(lg) {
      margin-top: rem-calc(32);
    }
    &> div {
      margin-bottom: rem-calc(75);
      @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(100);
      }
      text-align: center;
      @include make-col-ready();
      @include media-breakpoint-up(md) {
        @include make-col(6);
        &:last-child {
          @include make-col(12);
        }
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
        &:last-child {
          @include make-col(4);
        }
      }

      &:first-child, &:last-child {
        padding-top: rem-calc(21);
      }

      &:last-child {
        margin-bottom: rem-calc(100);
      }

      img {
        margin-bottom: rem-calc(21);
      }

      p {
        margin-bottom: rem-calc(32);
      }

    }
  }
}
