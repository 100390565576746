// REFERENCES

#references {
  .container {
    &> div {
      @include make-row();
      @include make-col-ready();
      //@include make-col(12);
      margin-bottom: rem-calc(10);
        p {
          @include fontSize(11px);
        }

      }
    }

}
