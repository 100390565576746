// MEDIA OBJECT

.media {
  img {
    &.top {
      align-self: flex-start;
    }
    &.middle {
      align-self: center;
    }
    &.bottom {
      align-self: flex-end;
    }
    margin-right: rem-calc(35);
  }
  .media-body {
    &.top {
      align-self: flex-start;
    }
    &.middle {
      align-self: center;
    }
    &.bottom {
      align-self: flex-end;
    }

    //Created for Pharmacy Information Slip
    img {
      float: right;
      width: 125px;
      margin-right: 0;
      padding-left: rem-calc(15);
      height: auto;
      margin-bottom: rem-calc(-50);
      @include media-breakpoint-up(md) {
        margin-right: 0;
        padding-left: rem-calc(35);
        width: 150px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: rem-calc(-40);
        margin-right: rem-calc(-65);
        padding-left: rem-calc(25);
        width: 143px;
      }
      @include media-breakpoint-up(xl) {
        width: 190px;
        margin-top: rem-calc(-40);
        margin-right: rem-calc(-100);
        padding-left: rem-calc(30);
      }
    }
  }

  &.stacked {
    @include media-breakpoint-down(xs) {
      display: block;
      text-align: center;
      img {
        margin-right: 0;
        margin-bottom: rem-calc(25);
      }

      .media-body {
        text-align: center;
        @include media-breakpoint-up(md) {
          text-align: left;
        }
        :not(a) {
          text-align: left;
        }
      }
    }
    a.btn.btn-success {
      margin-top: rem-calc(32) - $paragraph-margin-bottom;
    }
  }

}

#media-stack {
  margin-top: rem-calc(55);
  @include media-breakpoint-up(xl) {
    padding-right: rem-calc(165);
    //@include make-col-offset(2);
  }
  .media-body {
    margin-bottom: rem-calc(85);
  }
}
