// Patients

#patients {
  .callout {
    margin-top: rem-calc(55);
    @include media-breakpoint-up(md) {
      margin-top: rem-calc(65);
    }
  }

  #media-stack {
    padding-bottom: rem-calc(24);
    @include media-breakpoint-up(md) {
      padding-bottom: rem-calc(48);
    }
  }
}

// Hides telephone CTA button on mobile (Ordering for Patients page)
.telephone-cta {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
