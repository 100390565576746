//TYPEOGRAPHY
h2, .h2 {
  color: $professional-color;
  font-weight: $font-weight-bold;
  margin-bottom: rem-calc(32);
}

h3, .h3 {
  color: $primary;
  margin-bottom: rem-calc(15);
  &.small {
    font-size: 115%;
  }
}

h4, .h4 {
  color: $primary;
}

h2, h3, h4 {
  sup {
    font-size: 65%;
    top: -.6em;
  }
}