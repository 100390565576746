// How to purchase

#how-to-purchase {

  #page-header {
    //align-items: center;
    @include make-row();
    //justify-content: space-between;
    margin-right: auto;
    margin-left: auto;

    h3 {
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &> div {
      article:not(:last-child) {
        margin-bottom: rem-calc(25);
      }
      //@include make-col-ready();
      &:first-child {
        //margin-bottom: rem-calc(30);
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            @include make-col(7);
            padding-right: rem-calc(45);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(8);
          padding-right: rem-calc(65);
        }
      }

      &:last-child {
        margin-right: auto;
        margin-left: auto;
        padding-right: rem-calc(125);
        @include media-breakpoint-up(md) {
            @include make-col(5);
            padding-right: 0;
        }
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
      }
    }
  }

  #media-stack {
    @include media-breakpoint-down(sm) {
      margin-top: rem-calc(15);
    }

    h4 {
      margin-bottom: rem-calc(85);
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

}
